import styled from "styled-components";
import React from "react";
import { IoArrowForward, IoArrowBack } from "react-icons/io5";
import { BsCart, BsSearch } from "react-icons/bs";
import { GreenShopButton, GreenIconStyle, MsgWrapper } from "./styles";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ForwardIcon = styled(IoArrowForward)``;
const BackwardIcon = styled(IoArrowBack)``;
const CartIcon = styled(BsCart)``;
const SearchIcon =styled(BsSearch)``;

const GreenButton = (props) => {
    const { t } = useTranslation();
    const col = props.color;
    
    return (
        <GreenShopButton to={props.to} color={col} target={props.target}>
            <GreenIconStyle color={col} yellow={props.yellow}>
                {props.icon === "cart" ? <CartIcon /> : 
                    props.icon === "search" ? <SearchIcon /> : 
                        props.icon === "backward" ? <BackwardIcon /> : <ForwardIcon />}
            </GreenIconStyle>
            <MsgWrapper>{props.searchButton ? t("Error.button"): props.msg }</MsgWrapper>
            

        </GreenShopButton>
    );
};
export default (GreenButton);